<template>
  <v-dialog
    v-if="isShowDialogStorageDriveForm"
    v-model="isShowDialogStorageDriveForm"
    persistent
    max-width="90%"
  >
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <span class="headline"> {{ dataForm.id ? $t('storageDrive.storageDriveForm.actions.edit') : $t('storageDrive.storageDriveForm.actions.create') }} {{$t('storageDrive.storageDriveForm.form.titleForm')}}</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="dataForm.title"
            :rules="titleRules"
            label="Title"
            outlined
            dense
          />
          <v-text-field v-model="dataForm.key" :rules="fieldRules" label="Key" outlined dense />
          <v-text-field v-model="dataForm.secret" :rules="fieldRules" label="Secret" outlined dense />
          <v-text-field v-model="dataForm.region" :rules="fieldRules" label="Region" outlined dense />
          <v-text-field v-model="dataForm.prefixBucket" :rules="fieldRules" label="Prefix Bucket" outlined dense />
          <v-text-field v-model="dataForm.endpoint" :rules="fieldRules" label="Endpoint" outlined dense />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey"
            @click="isShowDialogStorageDriveForm = false"
            v-text="$t('user.userForm.actions.close')"
          />
          <v-btn
            color="info"
            :disabled="!valid"
            @click="save"
            v-text="$t('user.userForm.actions.save')"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  // Vuex
  import { sync } from 'vuex-pathify'
  export default {
    name: 'DialogStorageDriveFormUpdate',
    data: () => ({
      valid: true,
      titleRules: [
        (v) => !!v || 'Title is required',
        (v) => (v && v.length <= 255) || 'Max 255 characters',
      ],
      fieldRules: [
        (v) => !!v || 'Field is required',
        (v) => (v && v.length <= 255) || 'Max 255 characters',
      ],
    }),
    computed: {
      ...sync('adminStorageDrive', [
        'isShowDialogStorageDriveForm',
        'dataForm',
        'params',
      ]),
    },
    methods: {
      async save () {
        const valueCheck = this.$refs.form.validate()
        if (!valueCheck) {
          return
        }

        const dataStorageDriveUpdateResult = await this.$store.dispatch(`adminStorageDrive/${!this.dataForm.id ? 'create' : 'update'}StorageDrive`, this.getTokenLogin())
        if (dataStorageDriveUpdateResult.status === false) {
          // this.$toast.error(dataStorageDriveUpdateResult.data.message || 'Not Match')
          if (dataStorageDriveUpdateResult.data.length > 0) {
            dataStorageDriveUpdateResult.data.forEach(element => {
            const keyObject = Object.keys(element)
              this.$toast.error(keyObject + ': ' + element[keyObject].message)
          });
          } else {
            this.$toast.error(dataStorageDriveUpdateResult.data.message)
          }
          return
        }
        this.$toast.success(`${!this.dataForm.id ? 'Created' : 'Updated'} Storage Drive Success`)
        setTimeout(() => {
          if (this.params.offset !== 1) {
            this.params.offset = 1
          } else {
            this.$store.dispatch('adminStorageDrive/fetchDataStorageDriveList', this.getTokenLogin())
          }
          this.isShowDialogStorageDriveForm = false
        }, 1000)
      },
    },
  }
</script>
